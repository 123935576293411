<template>
    <div class="row user-hover" @click="$emit('selectUser', user.id)">
        <div class="col-3" :class="active == user.id ? 'selected-user' : ''">
            <img :src="user.image" style="width:50px;height:50px; border-radius:50px"/>
        </div>
        <div class="col-9" style="text-align:left">
            <div>
                {{user.names}}
            </div>
            <div class="text-muted">
                {{user.username}}
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment'
export default {
    props: ['user', 'date', 'active'],
    methods: {
        filterDate (date) {
            return moment(date).format('DD MMM YYYY HH:mm')
        }
    }
}
</script>
<style>
    .user-hover{
        padding: 10px;
        border-radius: 10px;
    }
    .user-hover:hover{
        cursor: pointer;
        background-color: #1D212C;
        transition: .5s all;
    }
    .selected-user{
        border-left: 5px solid #E3C87A ;
    }
</style>