<template>
    <b-card no-body class="perfil_boy">
        <b-tabs content-class="mt-3">
            <b-tab title="Chats" active @click="loadData">
                <div class="chat-container" v-if="loading == false">
                <UserContainer :active="active" @selectUser="selectUser" :user="user" v-for="user in conversations" :key="user.id"/>
                </div>
                <Loading v-else />
            </b-tab>
            <b-tab title="Users" @click="getUsers">
                <div class="chat-container" v-if="loading == false">
                <UserContainer :active="active" @selectUser="selectUser" :user="user" v-for="user in conversations" :key="user.id"/>
                </div>
                <Loading v-else />
            </b-tab>
        </b-tabs>
    </b-card>
</template>
<script>
import UserContainer from './UserContainer.vue'
import { mapActions, mapState } from 'vuex'
export default {
    components: { UserContainer },
    data () {
        return {
            loading: false,
            active: null
        }
    },
    created () {
        this.loadData()
    },
    methods: {
        ...mapActions('chat', ['getConversations', 'getChat', 'getUsersToChat']),
        loadData () {
            this.loading = true
            this.getConversations().then(() => {
                this.loading = false
            })
        },
        getUsers () {
            this.loading = true
            this.getUsersToChat().then(() => {
                this.loading = false
            })
        },
        selectUser (id) {
            this.active = id
            this.$emit('selected', id)
        }
    },
    computed: {
        ...mapState('chat', ['conversations'])
    }
}
</script>
<style scoped>
    .perfil_boy{
        max-height: 800px !important;
        min-height: 0px !important;
    }
</style>