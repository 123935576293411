<template>
    <div class="bubbleWrapper" :class="side">
      <div class="inlineContainer" :class="side == 'right' ? 'reverse' : ''">
        <div class="inlineIcon pt-2">
          <img
            src="https://cdn1.iconfinder.com/data/icons/ninja-things-1/1772/ninja-simple-512.png"
          />
          <div>
            <span class="other">{{ filterTime(hour) }}</span>
          </div>
        </div>
        <div :class="side == 'left' ? 'otherBubble other' : 'ownBubble own'">
            <a :href="file" v-if="file != null" style="color:white; margin-left:10px; margin-right:10px" target="_blank">
                <b-icon icon="file-earmark-arrow-up" />
            </a>
            {{text}}
        </div>
      </div>
    </div>
</template>
<script>
import moment from 'moment'
export default {
    props: {
        side: {
            type: String,
            default: 'left'
        },
        text: {
            type: String
        },
        hour: {
            type: String
        },
        file: {
            default: null
        }
    },
    methods: {
        filterTime (date) {
            return moment(date).format('HH:mm')
        }
    }
}
</script>
<style scoped>
 .reverse {
     flex-flow: row-reverse;
 }   
.bubbleWrapper {
  padding: 10px 10px;
  color: #fff;
}
.left {
  text-align: left !important;
}
.right {
  text-align: right !important;
}
.inlineContainer {
  display: inline-flex;
}
.inlineContainer.own {
  flex-direction: row-reverse;
}
.inlineIcon img {
  width: 20px;
  object-fit: contain;
}
.ownBubble {
  min-width: 60px;
  max-width: 700px;
  padding: 14px 18px;
  margin: 6px 8px;
  background-color: #5b5377;
  border-radius: 16px 16px 0 16px;
  border: 1px solid #443f56;
}
.otherBubble {
  min-width: 60px;
  max-width: 700px;
  padding: 14px 18px;
  margin: 6px 8px;
  background-color: #6c8ea4;
  border-radius: 16px 16px 16px 0;
  border: 1px solid #54788e;
}
.own {
  align-self: flex-end;
}
.other {
  align-self: flex-start;
}
span.own,
span.other {
  font-size: 14px;
  color: grey;
}
</style>