<template>
    <div class="dah_body">
        <div class="row" style="width:100%">
            <div class="col-sm-12 col-md-5 mb-3">
                <Contacts @selected="selectUser" />
            </div>
            <div class="col-sm-12 col-md-7 mb-3">
                <Chat :selected="selected" />
            </div>
        </div>
    </div>
</template>
<script>
import Chat from './Chat.vue'
import Contacts from './Contacts.vue'
export default {
    components: { Chat, Contacts },
    data () {
        return {
            selected: null
        }
    },
    methods: {
        selectUser (data) {
            this.selected = data
        }
    }
}
</script>